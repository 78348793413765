<template>
    <v-container fill-height fluid class="login-view">
        <v-row align="center" justify="center">
            <v-col>
                <v-card class="mx-auto pt-3 pl-3 pr-3 pb-2" max-width="450">
                    <v-card-text>
                        <div class="d-flex justify-center mb-10 mt-3">
                            <img src="../assets/img/movice_one_logo.svg" width="320px" height="auto" />
                        </div>

                        <!-- <div>{{ $t('name') }}</div> -->

                        <p class="text-h5 text--primary">
                            {{ $t("routes.login") }}
                        </p>

                        <v-text-field
                            v-model="form.email"
                            :error-messages="errors.email"
                            :hide-details="!errors.email"
                            :label="$t('login.email')"
                            :disabled="loading"
                            required
                            outlined
                            prepend-inner-icon="mdi-email"
                            :placeholder="!autofilled ? ' ' : ''"
                        >
                        </v-text-field>

                        <v-text-field
                            class="mt-3"
                            v-model="form.password"
                            type="password"
                            @keyup.enter="auth()"
                            :error-messages="errors.password"
                            :hide-details="!errors.password"
                            :disabled="loading"
                            :label="$t('login.password')"
                            required
                            outlined
                            prepend-inner-icon="mdi-lock"
                            :placeholder="!autofilled ? ' ' : ''"
                        >
                        </v-text-field>
                    </v-card-text>

                    <v-card-actions class="justify-end">
                        <v-btn
                            class="mr-2 mb-1 pa-5"
                            color="primary"
                            @click="auth()"
                            :loading="loading"
                        >
                            {{ $t("login.btn") }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import validator from "../plugins/validator";
import * as userValidation from "../api/user/validation";

export default {
    async mounted() {},

    data: () => ({
        loading: false,
        autofilled: false,

        errors: {
            email: "",
            password: ""
        },

        form: {
            email: null,
            password: null
        }
    }),
    
    watch: {
        'form.email'() {
            this.autofilled = true;
        },
    },

    methods: {
        async auth() {
            Object.assign(this.$data.errors, this.$options.data().errors);

            let validForm = validator(
                this.form,
                userValidation.auth,
                function(key, type, limit) {
                    let text = this.$t(`form_errors.${key}.${type}`);
                    this.errors[key] = text;
                }.bind(this)
            );

            if (!validForm) return;

            this.loading = true;

            const res = await this.$store.dispatch("api/user/auth", validForm);

            if (res.status == 200) {
                await this.$store.dispatch("api/user/current");
                if(this.$store.state.api.user.entityChangeListener) this.$store.state.api.user.entityChangeListener();
                this.$router.push({ name: "dashboard" });
            } else {
                this.loading = false;
                this.errors.email = " ";
                this.errors.password = this.$t("login.error_message");
            }
        }
    }
};
</script>
